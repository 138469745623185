body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* CSS Custom Properties */

:root {
	/* variables */
	--font-weight: 400;
	--font-weight-bold: 700;
	--font-weight-black: 900;

	/* 3:4 perfect fourth scale */
	/* @link https://utopia.fyi/type/calculator/?c=320,16,1.333,3840,20,1.333,5,2,&s=0.75|0.5|0.25,1.5|2|3|4|6,s-l&g=s,l,xl,12 */

	--font-size--2: clamp(0.5628rem, 0.55rem + 0.064vi, 0.7035rem);
	--font-size--1: clamp(0.7502rem, 0.7331rem + 0.0852vi, 0.9377rem);
	--font-size-0: clamp(1rem, 0.9773rem + 0.1136vi, 1.25rem);
	--font-size-1: clamp(1.333rem, 1.3027rem + 0.1515vi, 1.6663rem);
	--font-size-2: clamp(1.7769rem, 1.7365rem + 0.2019vi, 2.2211rem);
	--font-size-3: clamp(2.3686rem, 2.3148rem + 0.2692vi, 2.9607rem);
	--font-size-4: clamp(3.1573rem, 3.0856rem + 0.3588vi, 3.9467rem);
	--font-size-5: clamp(4.2087rem, 4.1131rem + 0.4783vi, 5.2609rem);

	--lineheight-high: 2.5;
	--lineheight-text: 1.5;
	--lineheight-short: 1.2;

	--text-block-width: 60ch;

	/* opacity */
	--opaque: 1;
	--semi-opaque: 0.75;
	--semi-transparent: 0.5;
	--transparent: 0.1;

	--colour-white: hsla(0, 0%, 100%, var(--opaque));
	--colour-white-transparent: hsla(0, 0%, 100%, var(--semi-opaque));
	--colour-black: hsla(0, 4%, 10%, var(--opaque));
	--colour-black-transparent: hsla(0, 4%, 10%, var(--semi-opaque));
	--colour-cool-black: hsl(210, 14%, 14%);
	--colour-base: #f9eaf0;
	--colour-alert: hsla(0, 90%, 67%);
	--colour-accent: hsl(14, 100%, 70%);
	--colour-accent-transparent: hsla(14, 100%, 70%, var(--semi-opaque));
	--colour-focus-outline: hsla(47, 21%, 65%, var(--semi-transparent));
	--colour-border: #9facdf;
	--colour-grey-light: hsl(0, 0%, 77%);
	--colour-grey-transparent: hsla(0, 0%, 78%, 0.1);
	--colour-text-dark: hsla(0, 0%, 0%, var(--semi-opaque));
	--colour-text-light: hsla(0, 0%, 100%, var(--semi-opaque));
	--colour-text-super-dark: hsla(200, 45%, 45%, var(--opaque));
	--colour-text-super-light: hsla(200, 36%, 55%, var(--opaque));
	--colour-text-sub-dark: hsla(31, 62%, 35%, var(--opaque));
	--colour-text-sub-light: hsla(31, 62%, 64%, var(--opaque));

	--colour-button-default: var(--colour-accent: );
	--colour-button-border: var(--colour-base);
	--colour-button-text: #fff;

	--border-radius-xx-small: 0.125rem;
	--border-radius-x-small: 0.25rem;
	--border-radius-small: 0.5rem;
	--border-radius-medium: 0.75rem;
	--border-radius-large: 1.5rem;
	--border-radius-circle: 50%;
	--border-width-hairline: 0.5px;
	--border-width: 1px;
	--border-width-thin: 2px;
	--border-width-thick: 5px;

	--box-shadow-hover: 0px 3px 12px 1px hsla(0, 0%, 0%, 0.14),
		0px 2px 28px hsla(0, 0%, 0%, 0.5);
	--box-shadow-mid: 0px 1px 9px hsla(0, 0%, 0%, 0.14),
		0px 1px 20px hsla(0, 0%, 0%, 0.36);
	--box-shadow: 0px 1px 3px hsla(0, 0%, 0%, 0.12),
		0px 1px 2px hsla(0, 0%, 0%, 0.24);

	--grid-64: 3fr 1fr 2fr 2fr 1fr 3fr;
	--grid-gap: 4vw;

	--max-width: 80rem;
	--spacing-tiny: 0.075rem;
	--spacing-xx-small: 0.125rem;
	--spacing-x-small: 0.25rem;
	--spacing-small: 0.5rem;
	--spacing: 0.75rem;
	--spacing-medium: 1rem;
	--spacing-large: 1.5rem;
	--spacing-x-large: 2rem;
	--spacing-xx-large: 3rem;

	--duration-instantly: 0;
	--duration-immediately: 0.1s;
	--duration-quickly: 0.2s;
	--duration-promptly: 0.5s;
	--duration-slowly: 1s;

	--cubbez: cubic-bezier(0.25, 0.8, 0.25, 1);
	--cubbez-long: 500mscubic-bezier(0.25, 0.8, 0.25, 1);
  box-sizing: border-box;
		-webkit-tap-highlight-color: transparent;
		/* font-display: optional; */
		font-family: "Albert Sans", "Trebuchet MS", sans-serif;
		-ms-text-size-adjust: 100%;
		-webkit-text-size-adjust: 100%;
		-webkit-font-smoothing: antialiased;
		font-feature-settings: "kern", "liga", "clig", "calt";
		font-kerning: normal;
}
html {
  color-scheme: dark;
  height: 100%;
}
body {
				--blue: var(--colour-text-super-light);
				--rust: var(--colour-text-sub-light);
				--body: var(--colour-black);
				--text: var(--colour-white-transparent);
				--nav: var(--colour-cool-black);
				background-color: var(--body);
				color: var(--text);
				margin: 0;
				display: flex;
				flex-direction: column;
				min-height: 100%;
			}
.btn {
	background-color: var(--body);
	border: 1px solid var(--text);
	border-radius: var(--border-radius-xx-small);
	color: var(--text);
	display: block;
	font-family: inherit;
	font-size: var(--font-size-0);
	font-weight: 400;
	margin: 1em;
	padding: var(--spacing) var(--spacing-medium);
	text-align: center;
	text-decoration: none;
	text-transform: capitalize;
	transition: background-color 500ms ease-in-out;
	width: fit-content;
	will-change: color;
	white-space: nowrap;
}
.btn.btnheading {
	border-color: var(--blue);
}
.btn.openbtn {
	text-wrap: nowrap;
	width: auto;
}
.btn:hover,
.btn:focus {
	background-color: var(--blue);
	color: var(--body);
}
.artdiv {
	height: 0px;
	display: none;
}
.artdiv.show {
	display: block;
	margin: var(--spacing-large);
	padding: var(--spacing-small);
	border: 2px solid var(--colour-white);
	height: fit-content;
}
.editdiv {
	border: 2px solid white;
    padding: 0px 1rem;
    margin-bottom: 1rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.editform {
	display: flex;
    align-content: flex-end;
	border: 1px transparent;
    align-items: flex-start;
	flex-wrap: wrap;
	padding: 2px 4px;
	width: 100%;
	> label {
		max-width: 50%;
		word-wrap: break-word;
	}
	> input, select {
		margin-left: var(--spacing-medium);
		        height: 1.3rem;
	}
}
.editform.red {
	border: 1px solid var(--colour-alert);
} 
.formfield {
	border: none;
	display: flex;
	align-items: flex-start;
	> input {
		margin-left: var(--spacing-small);
	}
}
button {
	    /* background-color: transparent; */
    /* border: 1px solid var(--rust); */
    padding: inherit;
	/* color: inherit; */
	background-color: var(--body);
	border: 1px solid var(--text);
	border-radius: var(--border-radius-xx-small);
	color: var(--text);
	display: block;
	font-family: inherit;
	/* font-size: var(--font-size-0); */
	font-weight: 400;
	/* margin: 1em; */
	/* padding: var(--spacing) var(--spacing-medium); */
	text-align: center;
	text-decoration: none;
	text-transform: capitalize;
	transition: background-color 500ms ease-in-out;
	width: fit-content;
	will-change: color;
	white-space: nowrap;
}
button:hover,
button:focus {
	background-color: var(--blue);
	color: var(--body);
}
::-webkit-scrollbar {
		width: 0.5rem;
	}
	::-webkit-scrollbar-track {
		background: var(--colour-black);
	}
	::-webkit-scrollbar-thumb {
		background: var(--colour-accent-transparent);
		background-clip: content-box;
	}
	::-webkit-scrollbar-thumb:hover {
		background: var(--colour-focus-outline);
	}
	::-webkit-scrollbar-corner {
		background: var(--colour-black);
	}
	*,
	*::after,
	*::before {
		box-sizing: border-box;
	}
	h2 {
		color: var(--rust);
		font-size: var(--font-size-1);
		font-style: italic;
		font-weight: 300;
		line-height: var(--lineheight-short);
		text-indent: var(--spacing-medium);
	}
	h3 {
		/* color: inherit; */
		font-size: var(--font-size-1);
		font-weight: 300;
	}
	h4 {
		color: inherit;
		font-size: var(--font-size-1);
		font-weight: 300;
	}
	p {
		color: inherit;
		font-size: var(--font-size-0);
		font-weight: 300;
		text-wrap: pretty;
	}

	img {
		border: 0 none;
		border-color: transparent;
		max-width: 100%;
		height: auto;
		vertical-align: middle;
		font-style: italic;
		background-repeat: no-repeat;
		background-size: cover;
		shape-margin: 0.75rem;
		object-fit: cover;
		object-position: center;
	}

	section a,
	p a,
	a :not(a div, a picture img, a picture, a img, a span, svg) {
		align-self: flex-start;
		color: var(--rust);
		background-color: transparent;
		border-bottom: var(--spacing-tiny) solid;
		border-bottom-color: var(--rust);
		font-weight: inherit;
		text-decoration: none;
		transition: color 500ms ease-in-out;
		-webkit-text-decoration-skip: objects;
	}
	a:hover,
	a:focus {
		color: var(--blue);
		border-bottom-color: var(--blue);
	}

	a img,
	a:link img,
	a:visited img {
		border: 0 none;
		border-color: transparent;
	}
	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
	input[type="number"] {
		-moz-appearance: textfield;
		appearance: textfield;
	}
	/* svg:not(:root) {
		overflow: clip;
	} */
	ol,
	ul {
		list-style: none;
	}
	li {
		margin: 0;
	}
	blockquote,
	q {
		quotes: none;
	}
	blockquote:before,
	blockquote:after,
	q:before,
	q:after {
		content: "";
		content: none;
	}
	table {
		border-collapse: collapse;
		border-spacing: 0;
	}
	button {
		font-weight: 400;
	}
	section {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  height: max-content;
  line-height: 1.5;
  margin: 0 auto;
  max-width: 100%;
  padding: var(--spacing-small) var(--spacing-medium);
  position: relative;
  width: 1200px;
}